.flag {
    background-image:url(/assets/flags/sprite.png);
    display:inline-block;
    overflow:hidden;
    background-repeat: no-repeat;
    position: relative;
    vertical-align: middle;
    box-sizing: content-box;
    background-size: 2438.10%;
}

.flag-ac {
    background-position: 8.55397% 53.6170%;
}
.flag-ad {
    background-position: 12.8310% 53.6170%;
}
.flag-ae {
    background-position: 17.1079% 53.6170%;
}
.flag-af {
    background-position: 21.3849% 53.6170%;
}
.flag-ag {
    background-position: 25.6619% 53.6170%;
}
.flag-ai {
    background-position: 29.9389% 53.6170%;
}
.flag-al {
    background-position: 34.2159% 53.6170%;
}
.flag-am {
    background-position: 38.4929% 53.6170%;
}
.flag-ao {
    background-position: 42.7699% 53.6170%;
}
.flag-aq {
    background-position: 47.0468% 53.6170%;
}
.flag-ar {
    background-position: 51.3238% 53.6170%;
}
.flag-as {
    background-position: 55.6008% 53.6170%;
}
.flag-at {
    background-position: 59.8778% 53.6170%;
}
.flag-au {
    background-position: 64.1548% 53.6170%;
}
.flag-aw {
    background-position: 68.4318% 53.6170%;
}
.flag-ax {
    background-position: 72.7088% 53.6170%;
}
.flag-az {
    background-position: 76.9857% 53.6170%;
}
.flag-ba {
    background-position: 81.2627% 53.6170%;
}
.flag-bb {
    background-position: 85.5397% 53.6170%;
}
.flag-bd {
    background-position: 89.8167% 53.6170%;
}
.flag-be {
    background-position: 94.0937% 53.6170%;
}
.flag-bf {
    background-position: 98.3707% 53.6170%;
}
.flag-bg {
    background-position: 0.00000% 62.5532%;
}
.flag-bh {
    background-position: 4.27699% 62.5532%;
}
.flag-bi {
    background-position: 8.55397% 62.5532%;
}
.flag-bj {
    background-position: 17.1079% 62.5532%;
}
.flag-bl {
    background-position: 21.3849% 62.5532%;
}
.flag-bm {
    background-position: 25.6619% 62.5532%;
}
.flag-bn {
    background-position: 29.9389% 62.5532%;
}
.flag-bo {
    background-position: 34.2159% 62.5532%;
}
.flag-bq {
    background-position: 38.4929% 62.5532%;
}
.flag-br {
    background-position: 42.7699% 62.5532%;
}
.flag-bs {
    background-position: 47.0468% 62.5532%;
}
.flag-bt {
    background-position: 51.3238% 62.5532%;
}
.flag-bv {
    background-position: 55.6008% 62.5532%;
}
.flag-bw {
    background-position: 59.8778% 62.5532%;
}
.flag-by {
    background-position: 12.8310% 62.5532%;
}
.flag-bz {
    background-position: 64.1548% 62.5532%;
}
.flag-ca {
    background-position: 68.4318% 62.5532%;
}
.flag-cc {
    background-position: 72.7088% 62.5532%;
}
.flag-cd {
    background-position: 76.9857% 62.5532%;
}
.flag-cefta {
    background-position: 81.2627% 62.5532%;
}
.flag-cf {
    background-position: 85.5397% 62.5532%;
}
.flag-cg {
    background-position: 89.8167% 62.5532%;
}
.flag-ch {
    background-position: 94.0937% 62.5532%;
}
.flag-ci {
    background-position: 98.3707% 62.5532%;
}
.flag-ck {
    background-position: 4.27699% 71.4894%;
}
.flag-cl {
    background-position: 8.55397% 71.4894%;
}
.flag-cm {
    background-position: 12.8310% 71.4894%;
}
.flag-cn {
    background-position: 17.1079% 71.4894%;
}
.flag-co {
    background-position: 21.3849% 71.4894%;
}
.flag-cp {
    background-position: 25.6619% 71.4894%;
}
.flag-cr {
    background-position: 29.9389% 71.4894%;
}
.flag-cu {
    background-position: 34.2159% 71.4894%;
}
.flag-cv {
    background-position: 38.4929% 71.4894%;
}
.flag-cw {
    background-position: 42.7699% 71.4894%;
}
.flag-cx {
    background-position: 47.0468% 71.4894%;
}
.flag-cy {
    background-position: 0.00000% 71.4894%;
}
.flag-cz {
    background-position: 51.3238% 71.4894%;
}
.flag-de {
    background-position: 55.6008% 71.4894%;
}
.flag-dg {
    background-position: 59.8778% 71.4894%;
}
.flag-dj {
    background-position: 64.1548% 71.4894%;
}
.flag-dk {
    background-position: 68.4318% 71.4894%;
}
.flag-dm {
    background-position: 72.7088% 71.4894%;
}
.flag-do {
    background-position: 76.9857% 71.4894%;
}
.flag-dz {
    background-position: 81.2627% 71.4894%;
}
.flag-ea {
    background-position: 85.5397% 71.4894%;
}
.flag-ec {
    background-position: 89.8167% 71.4894%;
}
.flag-ee {
    background-position: 94.0937% 71.4894%;
}
.flag-eg {
    background-position: 98.3707% 71.4894%;
}
.flag-eh {
    background-position: 0.00000% 80.4255%;
}
.flag-er {
    background-position: 4.27699% 80.4255%;
}
.flag-es {
    background-position: 8.55397% 80.4255%;
}
.flag-es-ct {
    background-position: 12.8310% 80.4255%;
}
.flag-es-ga {
    background-position: 17.1079% 80.4255%;
}
.flag-es-pv {
    background-position: 21.3849% 80.4255%;
}
.flag-et {
    background-position: 25.6619% 80.4255%;
}
.flag-eu {
    background-position: 29.9389% 80.4255%;
}
.flag-fi {
    background-position: 34.2159% 80.4255%;
}
.flag-fj {
    background-position: 38.4929% 80.4255%;
}
.flag-fk {
    background-position: 42.7699% 80.4255%;
}
.flag-fm {
    background-position: 47.0468% 80.4255%;
}
.flag-fo {
    background-position: 51.3238% 80.4255%;
}
.flag-fr {
    background-position: 55.6008% 80.4255%;
}
.flag-ga {
    background-position: 59.8778% 80.4255%;
}
.flag-gb {
    background-position: 64.1548% 80.4255%;
}
.flag-gb-eng {
    background-position: 68.4318% 80.4255%;
}
.flag-gb-nir {
    background-position: 72.7088% 80.4255%;
}
.flag-gb-sct {
    background-position: 76.9857% 80.4255%;
}
.flag-gb-wls {
    background-position: 81.2627% 80.4255%;
}
.flag-gd {
    background-position: 85.5397% 80.4255%;
}
.flag-ge {
    background-position: 89.8167% 80.4255%;
}
.flag-gf {
    background-position: 94.0937% 80.4255%;
}
.flag-gg {
    background-position: 98.3707% 80.4255%;
}
.flag-gh {
    background-position: 0.00000% 89.3617%;
}
.flag-gi {
    background-position: 4.27699% 89.3617%;
}
.flag-gl {
    background-position: 12.8310% 89.3617%;
}
.flag-gm {
    background-position: 17.1079% 89.3617%;
}
.flag-gn {
    background-position: 21.3849% 89.3617%;
}
.flag-gp {
    background-position: 25.6619% 89.3617%;
}
.flag-gq {
    background-position: 29.9389% 89.3617%;
}
.flag-gr {
    background-position: 34.2159% 89.3617%;
}
.flag-gs {
    background-position: 38.4929% 89.3617%;
}
.flag-gt {
    background-position: 42.7699% 89.3617%;
}
.flag-gu {
    background-position: 47.0468% 89.3617%;
}
.flag-gw {
    background-position: 51.3238% 89.3617%;
}
.flag-gy {
    background-position: 8.55397% 89.3617%;
}
.flag-hk {
    background-position: 55.6008% 89.3617%;
}
.flag-hm {
    background-position: 59.8778% 89.3617%;
}
.flag-hn {
    background-position: 64.1548% 89.3617%;
}
.flag-hr {
    background-position: 68.4318% 89.3617%;
}
.flag-ht {
    background-position: 72.7088% 89.3617%;
}
.flag-hu {
    background-position: 76.9857% 89.3617%;
}
.flag-ic {
    background-position: 81.2627% 89.3617%;
}
.flag-id {
    background-position: 85.5397% 89.3617%;
}
.flag-ie {
    background-position: 89.8167% 89.3617%;
}
.flag-il {
    background-position: 98.3707% 89.3617%;
}
.flag-im {
    background-position: 0.00000% 98.2979%;
}
.flag-in {
    background-position: 4.27699% 98.2979%;
}
.flag-io {
    background-position: 8.55397% 98.2979%;
}
.flag-iq {
    background-position: 0.00000% 0.00000%;
}
.flag-ir {
    background-position: 4.27699% 0.00000%;
}
.flag-is {
    background-position: 8.55397% 0.00000%;
}
.flag-it {
    background-position: 12.8310% 0.00000%;
}
.flag-je {
    background-position: 21.3849% 0.00000%;
}
.flag-jm {
    background-position: 25.6619% 0.00000%;
}
.flag-jo {
    background-position: 29.9389% 0.00000%;
}
.flag-jp {
    background-position: 34.2159% 0.00000%;
}
.flag-ke {
    background-position: 38.4929% 0.00000%;
}
.flag-kg {
    background-position: 42.7699% 0.00000%;
}
.flag-kh {
    background-position: 47.0468% 0.00000%;
}
.flag-ki {
    background-position: 51.3238% 0.00000%;
}
.flag-km {
    background-position: 59.8778% 0.00000%;
}
.flag-kn {
    background-position: 64.1548% 0.00000%;
}
.flag-kp {
    background-position: 68.4318% 0.00000%;
}
.flag-kr {
    background-position: 72.7088% 0.00000%;
}
.flag-kw {
    background-position: 76.9857% 0.00000%;
}
.flag-ky {
    background-position: 55.6008% 0.00000%;
}
.flag-kz {
    background-position: 81.2627% 0.00000%;
}
.flag-la {
    background-position: 85.5397% 0.00000%;
}
.flag-lb {
    background-position: 89.8167% 0.00000%;
}
.flag-lc {
    background-position: 94.0937% 0.00000%;
}
.flag-li {
    background-position: 98.3707% 0.00000%;
}
.flag-lk {
    background-position: 4.27699% 8.93617%;
}
.flag-lr {
    background-position: 8.55397% 8.93617%;
}
.flag-ls {
    background-position: 12.8310% 8.93617%;
}
.flag-lt {
    background-position: 17.1079% 8.93617%;
}
.flag-lu {
    background-position: 21.3849% 8.93617%;
}
.flag-lv {
    background-position: 25.6619% 8.93617%;
}
.flag-ly {
    background-position: 0.00000% 8.93617%;
}
.flag-ma {
    background-position: 29.9389% 8.93617%;
}
.flag-mc {
    background-position: 34.2159% 8.93617%;
}
.flag-md {
    background-position: 38.4929% 8.93617%;
}
.flag-me {
    background-position: 42.7699% 8.93617%;
}
.flag-mf {
    background-position: 47.0468% 8.93617%;
}
.flag-mg {
    background-position: 51.3238% 8.93617%;
}
.flag-mh {
    background-position: 55.6008% 8.93617%;
}
.flag-mk {
    background-position: 64.1548% 8.93617%;
}
.flag-ml {
    background-position: 68.4318% 8.93617%;
}
.flag-mm {
    background-position: 72.7088% 8.93617%;
}
.flag-mn {
    background-position: 76.9857% 8.93617%;
}
.flag-mo {
    background-position: 81.2627% 8.93617%;
}
.flag-mp {
    background-position: 85.5397% 8.93617%;
}
.flag-mq {
    background-position: 89.8167% 8.93617%;
}
.flag-mr {
    background-position: 94.0937% 8.93617%;
}
.flag-ms {
    background-position: 98.3707% 8.93617%;
}
.flag-mt {
    background-position: 0.00000% 17.8723%;
}
.flag-mu {
    background-position: 4.27699% 17.8723%;
}
.flag-mv {
    background-position: 8.55397% 17.8723%;
}
.flag-mw {
    background-position: 12.8310% 17.8723%;
}
.flag-mx {
    background-position: 17.1079% 17.8723%;
}
.flag-my {
    background-position: 59.8778% 8.93617%;
}
.flag-mz {
    background-position: 21.3849% 17.8723%;
}
.flag-na {
    background-position: 25.6619% 17.8723%;
}
.flag-nc {
    background-position: 29.9389% 17.8723%;
}
.flag-ne {
    background-position: 34.2159% 17.8723%;
}
.flag-nf {
    background-position: 38.4929% 17.8723%;
}
.flag-ng {
    background-position: 42.7699% 17.8723%;
}
.flag-ni {
    background-position: 47.0468% 17.8723%;
}
.flag-nl {
    background-position: 51.3238% 17.8723%;
}
.flag-no {
    background-position: 55.6008% 17.8723%;
}
.flag-np {
    background-position: 59.8778% 17.8723%;
}
.flag-nr {
    background-position: 64.1548% 17.8723%;
}
.flag-nu {
    background-position: 68.4318% 17.8723%;
}
.flag-nz {
    background-position: 72.7088% 17.8723%;
}
.flag-om {
    background-position: 76.9857% 17.8723%;
}
.flag-pa {
    background-position: 81.2627% 17.8723%;
}
.flag-pe {
    background-position: 85.5397% 17.8723%;
}
.flag-pf {
    background-position: 89.8167% 17.8723%;
}
.flag-pg {
    background-position: 94.0937% 17.8723%;
}
.flag-ph {
    background-position: 98.3707% 17.8723%;
}
.flag-pk {
    background-position: 4.27699% 26.8085%;
}
.flag-pl {
    background-position: 8.55397% 26.8085%;
}
.flag-pm {
    background-position: 12.8310% 26.8085%;
}
.flag-pn {
    background-position: 17.1079% 26.8085%;
}
.flag-pr {
    background-position: 21.3849% 26.8085%;
}
.flag-ps {
    background-position: 25.6619% 26.8085%;
}
.flag-pt {
    background-position: 29.9389% 26.8085%;
}
.flag-pw {
    background-position: 34.2159% 26.8085%;
}
.flag-py {
    background-position: 0.00000% 26.8085%;
}
.flag-qa {
    background-position: 38.4929% 26.8085%;
}
.flag-re {
    background-position: 42.7699% 26.8085%;
}
.flag-ro {
    background-position: 47.0468% 26.8085%;
}
.flag-rs {
    background-position: 51.3238% 26.8085%;
}
.flag-ru {
    background-position: 55.6008% 26.8085%;
}
.flag-rw {
    background-position: 59.8778% 26.8085%;
}
.flag-sa {
    background-position: 64.1548% 26.8085%;
}
.flag-sb {
    background-position: 68.4318% 26.8085%;
}
.flag-sc {
    background-position: 72.7088% 26.8085%;
}
.flag-sd {
    background-position: 76.9857% 26.8085%;
}
.flag-se {
    background-position: 81.2627% 26.8085%;
}
.flag-sg {
    background-position: 85.5397% 26.8085%;
}
.flag-sh {
    background-position: 89.8167% 26.8085%;
}
.flag-si {
    background-position: 94.0937% 26.8085%;
}
.flag-sj {
    background-position: 0.00000% 35.7447%;
}
.flag-sk {
    background-position: 4.27699% 35.7447%;
}
.flag-sl {
    background-position: 8.55397% 35.7447%;
}
.flag-sm {
    background-position: 12.8310% 35.7447%;
}
.flag-sn {
    background-position: 17.1079% 35.7447%;
}
.flag-so {
    background-position: 21.3849% 35.7447%;
}
.flag-sr {
    background-position: 25.6619% 35.7447%;
}
.flag-ss {
    background-position: 29.9389% 35.7447%;
}
.flag-st {
    background-position: 34.2159% 35.7447%;
}
.flag-sv {
    background-position: 38.4929% 35.7447%;
}
.flag-sx {
    background-position: 42.7699% 35.7447%;
}
.flag-sy {
    background-position: 98.3707% 26.8085%;
}
.flag-sz {
    background-position: 47.0468% 35.7447%;
}
.flag-ta {
    background-position: 51.3238% 35.7447%;
}
.flag-tc {
    background-position: 55.6008% 35.7447%;
}
.flag-td {
    background-position: 59.8778% 35.7447%;
}
.flag-tf {
    background-position: 64.1548% 35.7447%;
}
.flag-tg {
    background-position: 68.4318% 35.7447%;
}
.flag-th {
    background-position: 72.7088% 35.7447%;
}
.flag-tj {
    background-position: 76.9857% 35.7447%;
}
.flag-tk {
    background-position: 81.2627% 35.7447%;
}
.flag-tl {
    background-position: 85.5397% 35.7447%;
}
.flag-tm {
    background-position: 89.8167% 35.7447%;
}
.flag-tn {
    background-position: 94.0937% 35.7447%;
}
.flag-to {
    background-position: 98.3707% 35.7447%;
}
.flag-tr {
    background-position: 0.00000% 44.6809%;
}
.flag-tt {
    background-position: 4.27699% 44.6809%;
}
.flag-tv {
    background-position: 8.55397% 44.6809%;
}
.flag-tw {
    background-position: 12.8310% 44.6809%;
}
.flag-tz {
    background-position: 17.1079% 44.6809%;
}
.flag-ua {
    background-position: 21.3849% 44.6809%;
}
.flag-ug {
    background-position: 25.6619% 44.6809%;
}
.flag-um {
    background-position: 34.2159% 44.6809%;
}
.flag-un {
    background-position: 38.4929% 44.6809%;
}
.flag-us {
    background-position: 42.7699% 44.6809%;
}
.flag-uy {
    background-position: 29.9389% 44.6809%;
}
.flag-uz {
    background-position: 47.0468% 44.6809%;
}
.flag-va {
    background-position: 51.3238% 44.6809%;
}
.flag-vc {
    background-position: 55.6008% 44.6809%;
}
.flag-ve {
    background-position: 59.8778% 44.6809%;
}
.flag-vg {
    background-position: 64.1548% 44.6809%;
}
.flag-vi {
    background-position: 68.4318% 44.6809%;
}
.flag-vn {
    background-position: 72.7088% 44.6809%;
}
.flag-vu {
    background-position: 76.9857% 44.6809%;
}
.flag-wf {
    background-position: 81.2627% 44.6809%;
}
.flag-ws {
    background-position: 85.5397% 44.6809%;
}
.flag-xk {
    background-position: 89.8167% 44.6809%;
}
.flag-xx {
    background-position: 94.0937% 44.6809%;
}
.flag-ye {
    background-position: 94.0937% 89.3617%;
}
.flag-yt {
    background-position: 17.1079% 0.00000%;
}
.flag-za {
    background-position: 98.3707% 44.6809%;
}
.flag-zm {
    background-position: 0.00000% 53.6170%;
}
.flag-zw {
    background-position: 4.27699% 53.6170%;
}
