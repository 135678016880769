.review-green-background {
    background-color: #007741 !important;
}

.review-gray-background {
    background-color: #e3e7e3;
}

.review-split-background {
    background-image: linear-gradient(to right, #007741 50%, #e3e7e3 50%);
}

.review-card-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.075);
}

