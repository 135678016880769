html,
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

#__next {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

:root {
  --swiper-navigation-color: rgb(0, 0, 0);
}

.swiper {
  width: 100%;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
}

.loader-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.loader-text {
  margin-top: 12px;
  color: white;
  font-weight: 500;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(0.97);
  }
  100% {
    filter: brightness(1);
  }
}
