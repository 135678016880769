.youtube-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    cursor: pointer;
    width: 6rem;
    height: 6rem;
}

.youtube-play-button:hover {
    filter: brightness(85%);
}
