.loader-container {
    position: relative;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto !important;
}
